// triggers `onRouteChange` event for `window` on route change
export const onRouteUpdate = ({ location }) => {
  if (
    process.env.NODE_ENV !== `production` ||
    typeof window.gtag !== `function`
  ) {
    return
  }

  const pathIsExcluded =
    location &&
    typeof window.excludeGtagPaths !== `undefined` &&
    window.excludeGtagPaths.some((rx) => rx.test(location.pathname))

  if (pathIsExcluded) return

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    try {
      const pagePath = location
        ? location.pathname + location.search + location.hash
        : undefined
      window.gtag(`event`, `page_view`, { page_path: pagePath })
      if (
        process.env.NODE_ENV === `production` &&
        typeof window.fbq === `function`
      ) {
        // eslint-disable-next-line no-undef
        window.fbq(`track`, `ViewContent`)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => setTimeout(sendPageView, 750))
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 750)
  }

  return
}
