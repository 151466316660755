import React from "react"
import styled from "styled-components"
import { rem } from "polished"

import Link from "gatsby-link"

import LogoIcon from "../svg/logo-icon"
import LogoText from "../svg/logo-text"

const StyledLink = styled(Link)``

const LogoContainer = styled.div`
  z-index: 2;

  ${StyledLink} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media ${(props) => props.theme.xxlargeUp} {
    width: ${rem(196)};
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -25%);
  }

  @media ${(props) => props.theme.xxlargeDown} and ${(props) =>
      props.theme.largeUp} {
    width: ${rem(50)};
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -25%);
  }

  @media ${(props) => props.theme.largeDown} {
    width: ${rem(40)};
    position: relative;
    display: inline-block;
    vertical-align: top;
  }

  @media ${(props) => props.theme.largeDown} and ${(props) =>
      props.theme.smallUp} {
    margin-left: ${rem(10)};
  }

  @media ${(props) => props.theme.smallDown} {
    margin-left: ${rem(-10)};
  }

  svg {
    position: absolute;
  }

  &::after {
    content: "";
    display: block;

    @media ${(props) => props.theme.xxlargeUp} {
      padding-top: 27.04%;
    }

    @media ${(props) => props.theme.xxlargeDown} {
      padding-top: 117.777778%;
    }
  }

  svg:first-of-type {
    @media ${(props) => props.theme.xxlargeUp} {
      width: 23%;
      left: 0;
      top: 0;
      height: 100%;
    }

    @media ${(props) => props.theme.xxlargeDown} {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  svg:last-of-type {
    width: 70.1%;
    left: 29.75%;
    top: 11%;
    height: 83.018%;

    @media ${(props) => props.theme.xxlargeDown} {
      display: none;
    }
  }
`

const Logo = () => (
  <LogoContainer>
    <StyledLink to="/">
      <LogoIcon />

      <LogoText />
    </StyledLink>
  </LogoContainer>
)

export default Logo
