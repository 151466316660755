import React from "react"
import { RemoveScroll } from "react-remove-scroll"
import styled, { css } from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"
import Link from "gatsby-link"

import Logo from "./logo"

/* c8 ignore next 100 */
const Container = styled.div`
  @media ${(props) => props.theme.largeDown} {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.colorTangerine};
    transition: right 0.3s;
  }

  ${(props) =>
    props.open &&
    css`
      @media ${(props) => props.theme.largeDown} {
        right: 0;
      }
    `}
`
const Inner = styled.nav`
  @media ${(props) => props.theme.largeUp} {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  ul {
    @media ${(props) => props.theme.largeUp} {
      display: flex;
    }

    @media ${(props) => props.theme.largeDown} and ${(props) =>
        props.theme.smallUp} {
      width: 100%;
      font-size: ${rem(40)};
    }

    @media ${(props) => props.theme.smallDown} {
      width: 100%;
      font-size: ${rem(30)};
    }

    &:first-of-type {
      @media ${(props) => props.theme.largeUp} {
        li:not(:last-of-type) {
          margin-right: ${rem(34)};
        }
      }
    }

    &:last-of-type {
      @media ${(props) => props.theme.largeUp} {
        li:not(:first-of-type) {
          margin-left: ${rem(34)};
        }
      }
    }

    a {
      @media ${(props) => props.theme.xlargeUp} {
        position: relative;
        overflow: hidden;

        &::after {
          content: "";
          position: absolute;
          top: 100%;
          margin-top: 3px;
          left: 0;
          width: 100%;
          height: 1px;
          background: currentColor;
          opacity: 0.4;
          transition: transform 0.5s, transform-origin 0s 0s;
          transform: scaleX(0);
          transform-origin: right;
        }

        &:hover,
        &.active {
          &::after {
            transform-origin: left;
            transform: scaleX(1);
            transition: transform 0.5s 0s, transform-origin 0s 0s;
          }
        }
      }
    }
  }
`

const StyledRemoveScroll = styled(RemoveScroll)`
  @media ${(props) => props.theme.largeDown} {
    padding: ${rem(90)} ${rem(20)} ${rem(120)};
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
`

const Menu = (props) => (
  <React.Fragment>
    <Logo />

    <Container open={props.open}>
      <StyledRemoveScroll enabled={props.open}>
        <Inner>
          <ul>
            {props.links.left.map((item, index) => (
              <li key={index}>
                <Link
                  activeClassName={`active`}
                  onClick={/* c8 ignore next */ () => props.menuToggle(false)}
                  to={item.url}
                >
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>

          <ul>
            {props.links.right.map((item, index) => (
              <li key={index}>
                <Link
                  activeClassName={`active`}
                  onClick={/* c8 ignore next */ () => props.menuToggle(false)}
                  to={item.url}
                >
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>
        </Inner>
      </StyledRemoveScroll>
    </Container>
  </React.Fragment>
)

export default Menu

Menu.propTypes = {
  open: PropTypes.bool,
  links: PropTypes.shape({
    left: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      })
    ).isRequired,
    right: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        text: PropTypes.string,
      })
    ).isRequired,
  }),
  menuToggle: PropTypes.func,
}
