import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import initSmartOutline from "@oddcamp/js-utils/src/smart-outline"
import smoothscroll from "smoothscroll-polyfill"

import { StoreProvider, StoreConsumer } from "../store"

import "../config/yup"

import Meta from "../components/meta"
import Header from "../components/header/"
import Footer from "../components/footer"
import CookiesConsent from "../components/cookies-consent"

import TagManager from "react-gtm-module"
import theme from "../theme"

import "../assets/stylesheets/fonts/woff.scss"
import "../assets/stylesheets/app/app.scss"

if (typeof window !== `undefined`) {
  smoothscroll.polyfill()
}

if (typeof document !== `undefined`) {
  initSmartOutline([
    `input:focus`,
    `button:focus`,
    `textarea:focus`,
    `select:focus`,
  ])
}

class Layout extends React.Component {
  componentDidMount() {
    if (typeof document !== `undefined`) {
      const tagManagerArgs = {
        gtmId: `GTM-T4CLNCT`,
      }
      TagManager.initialize(tagManagerArgs)
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <StoreProvider>
            <Meta />

            <StoreConsumer>
              {({ headerTheme }) => <Header headerTheme={headerTheme} />}
            </StoreConsumer>

            <main>{this.props.children}</main>

            <Footer />

            <CookiesConsent />
          </StoreProvider>
        </React.Fragment>
      </ThemeProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
