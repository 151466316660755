import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import truncateHtml from "truncate-html"

const Meta = ({
  lang,
  name,
  title,
  titlePattern,
  titleOverridePattern,
  keywords,
  description,
  socialImage,
  robotsNoIndex,
  robotsNoFollow,

  fbAppId,
  ogType,
  ogTitle,
  ogDescription,
  ogImage,

  twitterTitle,
  twitterDescription,
  twitterSite,
  twitterCreator,
  twitterImage,

  meta,
  link,
}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          lang
          name
          title
          titlePattern
          description
          socialImage
          twitterHandle
          fbAppId
          icon
          iconBgColor
          favIcon
          maskIcon
          maskIconColor
          siteUrl
          themeColorDark
          themeColorLight
        }
      }
      allContentfulMeta(limit: 1) {
        nodes {
          colorBg
          colorMain
          themeColorDark
          themeColorLight
          metaIconBgColor
          name
          socialImage {
            localFile {
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
          }
          maskIconColor
          favicon {
            localFile {
              publicURL
            }
          }
          metaIcon {
            localFile {
              publicURL
            }
          }
          maskImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  const defaults = data.site.siteMetadata
  const contentful = data.allContentfulMeta.nodes.length
    ? data.allContentfulMeta.nodes[0]
    : {}

  const contentfulSocialImage = contentful.socialImage
    ? defaults.siteUrl +
      contentful.socialImage.localFile.childImageSharp.fixed.src
    : null

  const favicon = contentful.favicon
    ? contentful.favicon.localFile.publicURL
    : null

  const contentfulMetaIcon = contentful.metaIcon
    ? contentful.metaIcon.localFile.publicURL
    : null

  const contentfulMaskIcon = contentful.maskImage
    ? contentful.maskImage.localFile.publicURL
    : null

  // general

  const metaLang = lang || defaults.lang || undefined

  const metaName = name || defaults.name || undefined

  const metaTitle = title || defaults.title || undefined

  let metaDescription = description || defaults.description || undefined

  const metaKeywords = keywords || undefined

  if (metaDescription) {
    metaDescription = truncateHtml(metaDescription, {
      length: 240,
      stripTags: true,
    })
  }

  const metaRobotsNoIndex = robotsNoIndex || defaults.robotsNoIndex || undefined

  const metaRobotsNoFollow =
    robotsNoFollow || defaults.robotsNofollow || undefined

  const metaImage =
    socialImage || contentfulSocialImage || defaults.socialImage || undefined

  // fb/og

  const metaFbAppId = fbAppId || defaults.fbAppId || undefined

  const metaOgTitle = ogTitle || metaTitle || undefined

  let metaOgDescription = ogDescription || metaDescription || undefined

  if (metaOgDescription) {
    metaOgDescription = truncateHtml(metaOgDescription, {
      length: 240,
      stripTags: true,
    })
  }

  const metaOgType = ogType || undefined

  const metaOgImage = ogImage || metaImage || undefined

  // twitter

  const metaTwitterTitle = twitterTitle || metaTitle || undefined

  let metaTwitterDescription =
    twitterDescription || metaDescription || undefined

  if (metaTwitterDescription) {
    metaTwitterDescription = truncateHtml(metaTwitterDescription, {
      length: 240,
      stripTags: true,
    })
  }

  const metaTwitterImage = twitterImage || metaImage || undefined

  const metaTwitterSite = twitterSite || defaults.twitterHandle || undefined

  const metaTwitterCreator =
    twitterCreator || defaults.twitterHandle || undefined

  // icons

  const metaIcon = favicon || contentfulMetaIcon || defaults.icon || undefined

  const metaIconBgColor =
    contentful.metaIconBgColor || defaults.iconBgColor || undefined

  const metaFavIcon =
    favicon || contentfulMetaIcon || defaults.favIcon || undefined

  const metaMaskIcon = contentfulMaskIcon || defaults.maskIcon || undefined

  const metaMaskIconColor =
    contentful.maskIconColor || defaults.maskIconColor || undefined

  const themeColorLight =
    contentful.themeColorLight || defaults.themeColorLight || undefined

  const themeColorDark =
    contentful.themeColorDark || defaults.themeColorDark || undefined

  const metaThemeColor = () => {
    const themeColorMeta = []

    if (typeof themeColorDark !== `undefined`) {
      themeColorMeta.push({
        name: `theme-color`,
        context: themeColorDark,
        media: `(prefers-color-scheme: dark)`,
      })
    }

    if (typeof themeColorLight !== `undefined`) {
      themeColorMeta.push({
        name: `theme-color`,
        context: themeColorLight,
        media: `(prefers-color-scheme: light)`,
      })
    }

    return themeColorMeta
  }

  // full title

  let metaFullTitle = ``
  if (titleOverridePattern) {
    metaFullTitle = metaTitle
  } else {
    const pattern = titlePattern || defaults.titlePattern || ``
    metaFullTitle = pattern.replace(`[PAGE_TITLE]`, metaTitle)
  }
  metaFullTitle = metaFullTitle.replace(`[SITE_NAME]`, metaName)

  // robots

  const metaRobotsIndex = metaRobotsNoIndex ? `noindex` : `index`
  const metaRobotsFollow = metaRobotsNoFollow ? `nofollow` : `follow`

  return (
    <Helmet
      htmlAttributes={{ lang: metaLang }}
      title={metaFullTitle}
      meta={[
        // general
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          name: `robots`,
          content: `${metaRobotsIndex}, ${metaRobotsFollow}`,
        },
        // fb/og
        {
          property: `fb_app_id`,
          content: metaFbAppId,
        },
        {
          property: `og:site_name`,
          content: metaName,
        },
        {
          property: `og:title`,
          content: metaOgTitle,
        },
        {
          property: `og:description`,
          content: metaOgDescription,
        },
        {
          property: `og:type`,
          content: metaOgType,
        },
        {
          property: `og:image`,
          content: metaOgImage,
        },
        // twitter
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTwitterTitle,
        },
        {
          name: `twitter:description`,
          content: metaTwitterDescription,
        },
        {
          name: `twitter:site`,
          content: metaTwitterSite,
        },
        {
          name: `twitter:creator`,
          content: metaTwitterCreator,
        },
        {
          name: `twitter:image:src`,
          content: metaTwitterImage,
        },
        // google+, schema.org, other
        {
          itemprop: `name`,
          content: metaFullTitle,
        },
        {
          itemprop: `description`,
          content: metaDescription,
        },
        {
          itemprop: `image`,
          content: metaImage,
        },
        // ie
        {
          name: `application-name`,
          content: metaName,
        },
        {
          name: `msapplication-tooltip`,
          content: metaName,
        },
        {
          name: `msapplication-TileImage`,
          content: metaIcon,
        },
        {
          name: `msapplication-TileColor`,
          content: metaIconBgColor,
        },
        // ios
        {
          name: `apple-mobile-web-app-title`,
          content: metaName,
        },
        {
          name: `apple-mobile-web-app-capable`,
          content: `yes`,
        },
        {
          name: `apple-mobile-web-app-status-bar-style`,
          content: metaIconBgColor,
        },
      ]
        .concat(meta || [])
        .concat(metaThemeColor())}
      link={[
        {
          rel: `image_src`,
          href: metaImage,
        },
        {
          rel: `icon`,
          href: metaFavIcon,
        },
        {
          rel: `mask-icon`,
          href: metaMaskIcon,
          color: metaMaskIconColor,
        },
      ].concat(link || [])}
    />
  )
}

Meta.propTypes = {
  lang: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  titlePattern: PropTypes.string,
  titleOverridePattern: PropTypes.bool,
  keywords: PropTypes.string,
  description: PropTypes.string,
  socialImage: PropTypes.string,
  robotsNoIndex: PropTypes.bool,
  robotsNoFollow: PropTypes.bool,

  fbAppId: PropTypes.string,
  ogType: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogImage: PropTypes.string,

  twitterTitle: PropTypes.string,
  twitterDescription: PropTypes.string,
  twitterSite: PropTypes.string,
  twitterCreator: PropTypes.string,
  twitterImage: PropTypes.string,

  meta: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.arrayOf(PropTypes.object),
}

export default Meta
