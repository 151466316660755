import React from "react"
import PropTypes from "prop-types"

const StoreContext = React.createContext()
const StoreConsumer = StoreContext.Consumer

class StoreProvider extends React.Component {
  state = {
    headerTheme: `bright`, // bright | dark
  }

  render() {
    const { headerTheme } = this.state

    const { setHeaderTheme } = this

    return (
      <StoreContext.Provider
        value={{
          headerTheme,
          setHeaderTheme,
        }}
      >
        {this.props.children}
      </StoreContext.Provider>
    )
  }

  setHeaderTheme = (val) => {
    this.setState({ headerTheme: val })
  }
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { StoreContext, StoreProvider, StoreConsumer }
