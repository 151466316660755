exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-digital-therapeutics-js": () => import("./../../../src/pages/digital-therapeutics.js" /* webpackChunkName: "component---src-pages-digital-therapeutics-js" */),
  "component---src-pages-for-patients-js": () => import("./../../../src/pages/for-patients.js" /* webpackChunkName: "component---src-pages-for-patients-js" */),
  "component---src-pages-for-providers-js": () => import("./../../../src/pages/for-providers.js" /* webpackChunkName: "component---src-pages-for-providers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-html-js": () => import("./../../../src/pages/privacy.html.js" /* webpackChunkName: "component---src-pages-privacy-html-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-terms-html-js": () => import("./../../../src/pages/terms.html.js" /* webpackChunkName: "component---src-pages-terms-html-js" */),
  "component---src-templates-press-js": () => import("./../../../src/templates/press.js" /* webpackChunkName: "component---src-templates-press-js" */)
}

